import React, { Component } from 'react';
// import './Navbar.css';
// import './Crousel.css';
import './scss/Crousel.css';
import cargo from './images/crousel/cargo.png';
import warehouse from './images/crousel/warehouse.png';
import man from './images/crousel/man.png';

class Crousel extends Component {
    render() {
        return (
            <div>
              
<div id="carouselExampleCaptions" className="carousel slide" data-ride="carousel">
  <ol className="carousel-indicators ">
    <li data-target="#carouselExampleCaptions" data-slide-to="0" className="active"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="1"></li>
    <li data-target="#carouselExampleCaptions" data-slide-to="2"></li>
  </ol>
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={cargo} className="d-block w-100 " alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h4>Trucking Service</h4>
        <p>We have different size and shape of truck</p>
      </div>
    </div>
    <div className="carousel-item">
      <img src={warehouse} className="d-block w-100 " alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h4>Warehouse Service</h4>
        <p>We have Warehouse in Delhi and nearby location the Delhi Region.</p>
		
      </div>
    </div>
	<div className="carousel-item">
      <img src={man} className="d-block w-100 " alt="..."/>
      <div className="carousel-caption d-none d-md-block">
        <h4>Delivery Service</h4>
        <p>We have Delivery Service</p>
      </div>
    </div>

  </div>
  <a className="carousel-control-prev" href="#carouselExampleCaptions" role="button" data-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="sr-only">Previous</span>
  </a>
  <a className="carousel-control-next" href="#carouselExampleCaptions" role="button" data-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="sr-only">Next</span>
  </a>
</div>  
            </div>
        )
    }
}

export default Crousel
