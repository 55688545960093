import React, { Component } from 'react';
import './scss/Contact.css';
import Navbar from './Navbar';
import Footer from './Footer';
import mobile from './images/contact/mobile.png';
import email from './images/contact/email.png';
import { Link } from 'react-router-dom';
class Contact extends Component {
    render() {
        return (
            <div>
               <Navbar />
               <div>
            
			<form>
            <h2 className="contact-heading">Contact Us</h2>
			<main className="container">
				<div className="row mt-5">
					<div className="col-md-4 col-sm-8 m-auto form-style">
						<div className="row pb-2 pt-2">
							<h3 className="contact-heading-main">Feel Free to Contact Us</h3>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group mt-2">
									<input type="name" className="form-control" placeholder="enter your name" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<input type="email" className="form-control" placeholder="enter your email" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<input type="phone" className="form-control" placeholder="enter your phone number" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<div className="form-group">
									<input type="enquire" className="form-control" placeholder="enter your query" />
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col">
								<center><button type="button" className="btn submit-btn">Submit</button></center>
							</div>
						</div>


					</div>
				</div>
			</main>
			</form>
            <div className="contact-alternate">
                <div className="row">
                    <h2 className="heading get-in-touch">Get in touch</h2><br />
                </div>
                <div className="container">
                    <h3 className="heading for enquires">For Enquires:-</h3>
                </div>
                <div className=" container row">
                    <div className="col">
                        <p>
                        <img className="mobile" src={mobile}></img>
                        :
                        <a href="tel:9813464620">9813464620</a>
                        </p>
                        <p>
                        <img className="email" src={email}></img>
                        :
                        <a href="email">sonu@ygcservices.com</a>
                        </p>
                    </div>
                </div>
            </div>
                
            
		</div>
                 <Footer />
            </div>
        )
    }
}

export default Contact
