import React, { Component } from 'react';
import './scss/Services.css';
import Navbar from './Navbar';
import Footer from './Footer';
import truck from './images/services/truck.png';
import warehousing from './images/services/warehousing.png';
import deliveryman from './images/services/deliveryman.png';
import fast from './images/services/fast.png';
import RideWithYGC from './RideWithYGC';

class Services extends Component {
    render() {
        return (
            <div>
              <RideWithYGC />
            </div>
        )
    }
}

export default Services
