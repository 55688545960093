import React, { Component } from 'react';
import './scss/Footer.css';

class Footer extends Component {
    render() {
        return (
          <div>
          <div className="row py-4 d-flex align-items-center">
  <div className="col-md-6 col-lg-5 text-center text-md-left mb-4 mb-md-0">
      <h5 className="ygc-footer-heading mb-0"><center>Get connected with us on social networks!</center></h5>
    </div>
</div>

<footer className="page-footer font-small blue pt-4">


<div className="container-fluid text-center text-md-left">


<div className="row">
<div className="col-md-6 mt-md-0 mt-3">
  {/* <h5 className="text-uppercase">Know YGC</h5>
  <p1>YGC is a government certified company which provides complete logistics solution.</p1> <br/> */}
  <h4 className="ygc-popular-routes">Popular Outstation Routes</h4>
  
    <p className="ygc-routes">Delhi NCR to Mumbai</p> <br/>
    <p className="ygc-routes">Delhi NCR to Pune</p> <br/>
    <p className="ygc-routes">Delhi NCR to Ahemdabad</p> <br/>
    <p className="ygc-routes">Delhi NCR to Jaipur</p> <br/>

</div>

{/* <hr className="clearfix w-100 d-md-none pb-3"> */}


<div className="col-md-3 mb-md-0 mb-3">

  
  <h5 className="text-uppercase">Follow Us</h5>

  <ul className="list-unstyled">
    <li>
      {/* <a href="#!">Facebook</a> */}
      <a href="#" className="fa fa-facebook"></a>
    </li>
    <li>
      {/* <a href="#!">Instagram</a> */}
      <a href="#" className="fa fa-instagram"></a>
    </li>
    <li>
      {/* <a href="#!">Twitter</a> */}
      <a href="#" className="fa fa-twitter"></a>
    </li>
    <li>
      {/* <a href="#!">LinkedIN</a> */}
      <a href="#" className="fa fa-linkedin"></a>
    </li>
  </ul>

</div>
{/* </hr> */}

<div className="col-md-3 mb-md-0 mb-3">

  
  <h5 className="text-uppercase">Contact</h5>

  <ul className="list-unstyled">
    <li>
      <p className="ygc-footer-contact">Address:
            <a href="#!">Jhajjar Road,Rewari(Haryana)</a>
      </p>
          </li>
          <li>
      <p className="ygc-footer-contact">MOBILE:
            <a href="tel:9813464620">9813464620</a>
      </p>
          </li>
          <li>
      <p className="ygc-footer-contact">Email:
            <a href="#!">yogesh@ygcservices.com</a>
      </p>
      
    </li>
  </ul>
 

</div>
{/* </hr> */}


</div>


</div>




{/* <div className="footer-copyright text-center py-3">© 2020 Copyright:
<a href="https://mdbootstrap.com/"> ygcservices.com</a>
</div> */}


</footer>

      </div>
        )
    }
}

export default Footer
