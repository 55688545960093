import React, { Component } from 'react';
import './scss/HomeItems.css';
import { Link } from 'react-router-dom';

class HomeItems extends Component {
    render() {
        return (
            <div>
              
  <div className="container-fluid">
    <h1 className="ygc-home-main-heading">YGC SERVICES</h1>
        <div className=" container ygc-homeheading">
          <h1>YGC is a leading logistic service provider. We store, move and deliver your products safely and timely to
          meet your business demands.</h1>
        </div>
        <div className="container ygc-subhomeheading">
          We give technology based logistics solution to all businesses and help them grow to provide best service 
          and innovation based supply solutions.
        </div>
  
</div>
<div className="mainpart">
  <div className="container-fluid ygc-home-services">
  <h3 className="home-services-heading"> Our Services </h3> <br/>
  <div className="row">
  
    <div className="col-md-4">
      <h2>Trucking Services</h2>
      {/* <p>HERE YOU CAN BOOK YOUR TRUCK ACCORDING TO YOUR NEED. </p> */}
      <p>We have different types of trucks for the delivery.</p>
      <Link to="/services" className="btn btn-primary">View details »</Link>
      {/* <p><a className="btn btn-secondary" href="/services" role="button">View details »</a></p> */}
    </div>
    <div className="col-md-4">
      <h2>Warehouse Service</h2>
      {/* <p> HERE YOU CAN LOCATE YOUR NEARBY WAREHOUSE & ALSO BOOK YOUR SPACE.</p> */}
      <p>We have facility of Warehouse to store your material.</p>
      <Link to="/services" className="btn btn-primary">View details »</Link>
      {/* <p><a className="btn btn-secondary" href="/services" role="button">View details »</a></p> */}
    </div>
    <div className="col-md-4">
      <h2>GPS Tracking</h2>
      {/* <p>HERE YOU CAN TRACK YOUR SHIPMENT BY TRACKING ID.</p> */}
      <p>Now see your vehicle moving by GPS Tracking.</p>
      <Link to="/services" className="btn btn-primary">View details »</Link>
      {/* <p><a className="btn btn-secondary" href="/services" role="button">View details »</a></p> */}
    </div>
  </div>

</div> 
</div>
<div className="container keep-div"> <br />
  <br />
  <div className="row justify-content-center keep-img">
    <div className="fadeInUp animated">
      Business is better here!
    </div>
  </div>
  <div className="row-justify-content-center">
    <div>
     
      
      {/* <Link to="/contact" className="inquiry btn-iq"  type="button">Enquire</Link> */}
      <Link to="/contact" className="btn btn-primary">Enquire</Link>
     
   
    </div>
    <br />
    <br />
    <br />
    <br />
  </div>
</div>



            </div>
        )
    }
}

export default HomeItems
