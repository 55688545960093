import React from 'react';
// import logo from './logo.png';
import './App.css';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';
import Navbar from './components/Navbar';
import Crousel from './components/Crousel';
import HomeItems from './components/HomeItems';
import Footer from './components/Footer';

import HomePage from './components/HomePage';
import About from './components/About';
import RideWithYGC from './components/RideWithYGC';
import Services from './components/Services';
import Partner from './components/Partner';
import Contact from './components/Contact';


function App() {
  return (
  <Router> 
    <div className="App">
      <Switch>
        {/* <Navbar />
        <Crousel />
        <HomeItems />
        <Footer /> */}
        <Route path="/" exact component={HomePage} />
        {/* <HomePage /> */}
        <Route path="/about" component={About} />
        <Route path="/services" component={Services} />
        <Route path="/partner" component={Partner} />
        <Route path="/contact" component={Contact} />
        <Route path="/ridewithygc" component={RideWithYGC} />
        </Switch>
    </div>
  </Router>   
  );
}

export default App;
