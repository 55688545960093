import React, { Component } from 'react';
import './scss/RideWithYGC.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import alldaycontact from './images/RideWithYGC/alldaycontact.png';
import doortodoor from './images/RideWithYGC/doortodoor.png';
import GPSTracking from './images/RideWithYGC/GPSTracking.png';
// import multi from './images/RideWithYGC/multi.png';
import partload from './images/RideWithYGC/partload.png';


class RideWithYGC extends Component {
    render() {
        return (
            <div>
                <Navbar />
                <div claas="container">
                    <h2 className="ride-heading">Why ride with YGC?</h2>
                    <label className="ride-sub-heading">
                        The best way to travel to your destination
                    </label>
                <br />
                <div className="container witem-list-wrapper clearfix">
                <div className="row wd-50 mt-5 mb-5">
                    <div className="col-sm-6">
                <div className="container-card" >
                    <img className="card-img-top" src={partload} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title why-ygc">Part-Load Service</h5>
                            <p className="card-text">Now don't pay for the Full Truck. Pay Only for your space.</p>
                            <Link to="/contact" className="btn btn-primary">Get Details</Link>
                        </div>
                </div>
                </div>
                <div className="col-sm-6">
                <div className="container-card" >
                    <img className="card-img-top" src={doortodoor} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title why-ygc">Door-to-Door Delivery</h5>
                            <p className="card-text">We provide Door to Door Delivery Service without extra charge.</p>
                            <Link to="/contact" className="btn btn-primary">Get Details</Link>
                        </div>
                </div>
                </div>
                </div>
                <div className="row wd-50 mt-5 mb-5">
                    <div className="col-sm-6">
                <div className="container-card" >
                    <img className="card-img-top" src={GPSTracking} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title why-ygc">GPS Tracking</h5>
                            <p className="card-text">Now Track your Shipment from home by GPS Tracking.</p>
                            <Link to="/contact" className="btn btn-primary">Get Details</Link>
                        </div>
                </div>
                </div>
                <div className="col-sm-6">
                <div className="container-card" >
                    <img className="card-img-top" src={alldaycontact} alt="Card image cap"/>
                        <div className="card-body">
                            <h5 className="card-title why-ygc">27*7 Service</h5>
                            <p className="card-text">We provide 24*7 assist service to all our customers.</p>
                            <Link to="/contact" className="btn btn-primary">Get Details</Link>
                        </div>
                </div>
                </div>
                </div>
                </div>
                </div>
                
                <Footer />
            </div>
        )
    }
}

export default RideWithYGC

