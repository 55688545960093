import React, { Component } from 'react';
import './scss/Partner.css';
import Navbar from './Navbar';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import vehicles from './images/partner/vehicles.png';
import partner from './images/partner/partner.png';
import driving from './images/partner/driving.png';
import fleet from './images/partner/fleet.png';

class Partner extends Component {
    render() {
        return (
            <div>
                <Navbar />
                
                <div className=" fluid container">
                
                  <div className="row">
                    <img src={vehicles} className="img-fluid" alt="Responsive image"/>
                  </div>
                  
                  <div className="max-width">
                      <h1 className="title-header">Make Money. Earn Respect. Secure Your Future. </h1>
                      <h4 className="title-text">Apply now to become a YGC partner. Start earning in 24 hours!</h4>
                      <div className="row row-cols-1 row-cols-md-3">
                            <div className="col mb-4">
                                <div className="card h-100">
                                <img src={partner} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Join YGC as a</h5>
                                    <p className="card-text">Partner with a Vehicle</p>
                                    <Link to="/contact" className="btn btn-outline-primary">
                                       Attach my Truck
                                     </Link>
                                </div>
                                </div>
                            </div>
                            <div className="col mb-4">
                                <div className="card h-100">
                                <img src={driving} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Know Driving?</h5>
                                    <p className="card-text">We've a vehicle for you!</p>
                                    <Link to="/contact" className="btn btn-outline-primary">
                                       I need a Truck
                                     </Link>
                                </div>
                                </div>
                            </div>
                            <div className="col mb-4">
                                <div className="card h-100">
                                <img src={fleet} className="card-img-top" alt="..." />
                                <div className="card-body">
                                    <h5 className="card-title">Become a</h5>
                                    <p className="card-text">Fleet Operator</p>
                                    <Link to="/contact" className="btn btn-outline-primary">
                                       Attach a Fleet
                                     </Link>
                                </div>
                                </div>
                            </div>
                            </div>
                  </div>
                  
                </div>
                <Footer />
            </div>
        )
    }
}

export default Partner
