import React, { Component } from 'react';
import './scss/About.css';
import Navbar from './Navbar';
import Footer from './Footer';
import aboutus from './images/about/aboutus.png';

class About extends Component {
    render() {
        return (
            <div>
				<Navbar />
				
				<h1 className="ygc-about-main-description">About Us</h1>
                    <img src={aboutus} className="image-about" alt="Responsive image"/>
				<div className="container-fluid">
				<div className="ygc-about-page">
					{/* <h1 className="ygc-about-description">YGC SERVICES</h1> */}
					<p className="container ygc-about-first-paragraph mt-5">
					YGC Services stores, moves and delivers your products with services
					 customized to meet the demands of your business On Time and In Full, every time. 
					 We are an Indian company providing logistics solutions to businesses looking for 
					 excellence and innovation. YGC works across sectors like consumer durables,
					  pharmaceuticals, automobiles, retail, FMCG and more. We pride ourselves on our long 
					  lasting relationships with a list of satisfied customers.</p>
					  <p className="container ygc-about-first-secondparagraph mb-5">
					We use our fleet of trucks and strategically located warehouses to maximize the efficiency
					 of your supply chain. No matter what your industry is, we take the initiative to 
					 understand what you do and prepare to meet your ever changing requirements with ease
					  and flexibility. To add to this, we have strong health and safety standards and fleet
					   maintenance protocols. Our focused and autonomous teams work to ensure immediate
					    problem solving, leaving work to flow smoothly in an uninterrupted system just 
						the way it is supposed to.
						</p>

						<div className="ygc-tagline-aboutus">
							Business is better here!
						</div>
				</div>
				
            </div>
			<Footer />
			</div>
        )
    }
}

export default About
